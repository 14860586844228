/* Radio Canada Font */
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300;400;500;600;700&display=swap');

/* Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Mulish Font */
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,700;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'RFTone';
		font-weight: 200;
		src: url('/src/UI/assets/fonts/RFTone/RFToneExtralight.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 300;
		src: url('/src/UI/assets/fonts/RFTone/RFToneLight.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 400;
		src: url('/src/UI/assets/fonts/RFTone/RFToneRegular.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 600;
		src: url('/src/UI/assets/fonts/RFTone/RFToneSemibold.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 700;
		src: url('/src/UI/assets/fonts/RFTone/RFToneBold.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 800;
		src: url('/src/UI/assets/fonts/RFTone/RFToneExtrabold.otf');
	}
	@font-face {
		font-family: 'RFTone';
		font-weight: 900;
		src: url('/src/UI/assets/fonts/RFTone/RFToneBlack.otf');
	}
	@font-face {
		font-family: 'Louis George Cafe';
		font-weight: 700;
		src: url('/src/UI/assets/fonts/LouisGeorgeCafe/LouisGeorgeCafeBold.TTF');
	}
	@font-face {
		font-family: 'Louis George Cafe';
		font-weight: 300;
		font-style: italic;
		src: url('/src/UI/assets/fonts/LouisGeorgeCafe/LouisGeorgeCafeLightItalic.TTF');
	}
	@font-face {
		font-family: 'Louis George Cafe';
		font-weight: 300;
		src: url('/src/UI/assets/fonts/LouisGeorgeCafe/LouisGeorgeCafeLight.TTF');
	}
	@font-face {
		font-family: 'Louis George Cafe';
		font-weight: 400;
		src: url('/src/UI/assets/fonts/LouisGeorgeCafe/LouisGeorgeCafe.TTF');
	}
}

@layer utilities {
	/* Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	.no-scrollbar {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}
}

html,
body {
	box-sizing: border-box;
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: transparent;
}

body {
	font-family: 'RFTone', 'Radio Canada', 'sans-serif';
}

.min-w-19 {
	min-width: 18.75rem;
}

.min-h-4 {
	min-height: 1rem;
}

.min-h-5 {
	min-height: 1.25rem;
}

.w-7_5 {
	width: 1.875rem;
}

.w-22 {
	width: 5.688rem;
}

.w-25 {
	width: 6.25rem;
}

.w-26 {
	width: 6.75rem;
}

.w-37 {
	width: 9.25rem;
}

.w-45 {
	width: 11.25rem;
}

.w-47 {
	width: 11.625rem;
}

.w-84 {
	width: 21rem;
}

.w-97 {
	width: 26.875rem;
}

.h-6_5 {
	height: 1.7rem;
}

.h-7_5 {
	height: 1.875rem;
}

.h-22 {
	height: 5.688rem;
}

.h-55 {
	height: 13.75rem;
}

.h-100 {
	height: 28.125rem;
}

.right-back {
	width: 30px;
	height: 30px;
}

.rounded-cust {
	border-radius: 6.25rem;
}

.border-cust {
	border: 1px solid #000;
}

.shadow_box {
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.shadow_box-strong {
	box-shadow: -3px 4px 20px rgba(0, 0, 0, 25%);
}

/* typography */

.h1-Principal {
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: bold;
}

.h2-Subtitle {
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.body-Text2 {
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: 300;
}

.body-Text3 {
	font-size: 0.75rem;
	line-height: 0.875rem;
	font-weight: 300;
}

/* Circle */

.circle-perfil {
	font-size: 14px;
	color: #fff;
	background-color: #9cbdb9;
}

/* Hr */

.hr-configuration {
	height: 0.5px;
	border-style: none;
	justify-content: space-between;
}

.hr-users {
	height: 0.5px;
	width: 272px;
	border-style: none;
	justify-content: space-between;
	background-color: #cbbfd9;
	margin-bottom: 25px;
}

/* radio buttons */

input[type='radio'] + label span {
	transition: background 0.2s, transform 0.2s;
	border: 1px solid #9cbdb9;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
	transform: scale(1.2);
}

input[type='radio']:checked + label span {
	content: 'a';
	border: 1px solid #9cbdb9;
	background-color: #9cbdb9;
}

input[type='radio']:checked + label span {
	background: #9cbdb9;
}

input[type='checkbox']:hover + label span {
	filter: brightness(90%);
}

input[type='checkbox']:disabled + label span {
	background: #e6e6e6;
	opacity: 0.6;
	pointer-events: none;
}

input[type='radio']:checked + label span::after {
	content: '✓';
	color: #fff;
}

input.customCheckBox[type='checkbox'] {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.chooseFileButton {
	padding: 6 px 23 px;
	background: #3f4257;
	border-radius: 30px;
	color: white;
	font-weight: 300;
	font-size: 14px;
	margin: 10 px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
}

/* skeleton-screen-animation  */
.skeleton-animation-background-light {
	background: linear-gradient(to right, #f0f0f0 8%, #dedfe2 18%, #f5f5f5 33%);
	background-size: 800px 104px;
}

.skeleton-animation-background-dark {
	background: linear-gradient(to right, #dedfe2 8%, #f0f0f0 18%, #dedfe2 33%);
	background-size: 800px 104px;
}

.words-break {
	word-break: break-word;
}

@media only screen and (max-width: 767px) {
	.title-text {
		font-size: 2.125rem;
		margin-top: 0px;
	}
}

@layer base {
	.w-63 {
		width: 15.625rem;
	}
	.h-88 {
		height: 22.875rem;
	}
	.h-100 {
		height: 28.125rem;
	}
	.px-30 {
		padding-left: 7.25rem;
		padding-right: 7.25rem;
	}
	.ml-13 {
		margin-left: 3.375rem;
	}
	.w-68 {
		width: 17rem;
	}
	.right-organization-menu {
		right: 12.5rem;
	}
	.lg-right-organization-menu {
		right: 31rem;
	}
	.xl-right-organization-menu {
		right: 47rem;
	}
	.xxl-right-organization-menu {
		right: 63rem;
	}
	.w-42 {
		width: 10.5rem;
	}
	.w-49 {
		width: 12.25rem;
	}
}

/* edit to lazy loading so it takes the full screen*/
.lazyLoadingImages img {
	min-width: 100% !important;
	min-height: 100% !important;
}

.lazyLoadingImages {
	display: flex !important;
}

/* overrides styles of carousel slider and carousel thumb */

.carousel-container .carousel {
	display: flex;
	justify-content: center;
}
.carousel .thumb {
	height: 80px;
}
.carousel .thumb img {
	height: 100%;
}

/* DropZone Styles  */
@media (max-width: 768px) {
	.dz-ui-header span {
		font-size: 12px;
	}
	.dz-ui-label {
		padding: 0 8px;
	}
	div .dz-ui-footer {
		font-size: 12px !important;
	}
}

/* anamaria-velasco */
.anamaria-velasco .h-300 {
	height: 300px;
}

.anamaria-velasco .h-313 {
	height: 19.563rem;
}

.anamaria-velasco .h-400 {
	height: 400px;
}

.anamaria-velasco .h-600 {
	height: 600px;
}

.anamaria-velasco .h-696 {
	height: 43.5rem;
}

.anamaria-velasco .h-800 {
	height: 800px;
}

.anamaria-velasco .h-200 {
	height: 200px;
}

.anamaria-velasco .w-200 {
	width: 200px;
}

.anamaria-velasco .w-413 {
	width: 25.813rem;
}

.anamaria-velasco .w-454 {
	width: 28.375rem;
}

.anamaria-velasco .w-470 {
	width: 29.375rem;
}

.anamaria-velasco .w-500 {
	width: 31.25rem;
}

.anamaria-velasco > .w-544 {
	width: 34rem;
}

.anamaria-velasco .w-600 {
	width: 600px;
}

.anamaria-velasco .w-630 {
	width: 630px;
}

.anamaria-velasco .w-680 {
	width: 680px;
}

.anamaria-velasco > .w-700 {
	width: 700px;
}

.anamaria-velasco .w-800 {
	width: 800px;
}

.anamaria-velasco .w-888 {
	width: 55.5rem;
}

.anamaria-velasco .w-300 {
	width: 300px;
}

.anamaria-velasco .custom_hr {
	width: 25rem;
	border: 0;
	border-top: 1px solid;
}

.anamaria-velasco .text_color {
	color: #9cbdb9;
}

.anamaria-velasco .text_color_nor {
	color: #f5f5f5;
}

.anamaria-velasco .background_color {
	background-color: #9cbdb9;
}

.anamaria-velasco .back_color {
	background-color: #f5f5f5;
}

.anamaria-velasco .right-12 {
	right: 12rem;
}

.anamaria-velasco .mr-670 {
	margin-right: 41.875rem;
}

.anamaria-velasco .intro {
	font-family: Roboto;
	font-style: normal;
	font-weight: 900;
	font-size: 54px;
	line-height: 64px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .destacado {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 44px;
	line-height: 54px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .titulo {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 44px;
	line-height: 54px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .sub_titulo {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 34px;
	line-height: 40px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .texto {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 34px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .pie_de_pagina {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .title {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 34px;
	letter-spacing: 0.25px;
}

.anamaria-velasco .cursor-none {
	cursor: none;
}

@media only screen and (max-width: 767px) {
	.anamaria-velasco .texto {
		font-size: 20px;
		line-height: 30px;
	}

	.anamaria-velasco .title {
		font-size: 18px;
		line-height: 24px;
	}
}
